import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
// import Image from 'common/src/components/Image';
import GatsbyImgaeFiexedHeight from '../GatsbyImgaeFiexedHeight';

import Timeline from  "../Timeline/timeline"
import Event from  "../Timeline/event"

const ProgrammingHistorySection = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  timeline,
  event,
  companyTitle,
  companyRole,
  companyLocation,
  timelineContainer
}) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        PROGRAMMING_HISTORY {
          date
          content
          situation
        }
      }
    }
  `);

  return (    
    <Box {...sectionWrapper} as="section" id="programmingHistory_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading
            {...secTitle}
            content="Programming History"
          />
          <Text content={'iOS開発歴12年, Swift開発歴6年'} {...secDescription} />
        </Box>
        <Box {...timelineContainer}>          
          <Timeline className="Timeline" {...timeline} >
            {Data.portfolioJson.PROGRAMMING_HISTORY.map((item, index) => (
              <Event interval={`${item.date} - ${item.content}`} title={''} subtitle={''} {...event} >
                  <Text content={item.situation} {...secDescription} />
              </Event>
            ))}
          </Timeline>
        </Box>
      </Container>
    </Box>
  );
};

ProgrammingHistorySection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  timeline: PropTypes.object,
  event: PropTypes.object,
  helper: PropTypes.object,
};

ProgrammingHistorySection.defaultProps = {
  timelineContainer: {
    pl: ['8px', '20px', '46px', '46px']
  },
  event: {
    overflow: 'hidden',
    backgroundColor: '#0ff',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'stretch',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3, 1 / 1.5],
    justifyContent: 'center',
    alignItems: 'center',
  },
  helper: {
    display: 'inline-block',
    height: '100%',
    verticalAlign: 'middle'
  },
  companyTitle: {
    fontSize: ['18px', '20px', '20px', '24px', '24px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    textAlign: 'left',
    pt: '0px',
    pb: '0px',
    mb: '0px',
  },
  sectionWrapper: {
    pt: ['60px', '80px', '90px', '100px', '100px'],
  },
  secTitleWrapper: {
    mb: '40px',
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
    textAlign: 'left',
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.0em',
    mb: '0px',
    textAlign: 'left',
  }
};

export default ProgrammingHistorySection;
